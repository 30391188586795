import { FsMessage } from "fs-design";
/**
 *
 * 定义需要进行谷歌人机验证的站点
 */
const NEED_CHECK_GRECaPTCHA_HOST = ["www.fs.com", "front-platform.whgxwl.com", "newfs.whgxwl.com", "hardy.fs.com"];

const isNeedGrecaptcha = (host = "localhost") => {
	return NEED_CHECK_GRECaPTCHA_HOST.includes(host);
};

export default function () {
	const sitekey = "6Lf874IpAAAAACYfn7X2StpklJzoaN3JehO956Xc";
	const NeedRecaptcha = computed(() => {
		return isNeedGrecaptcha(window?.location?.hostname);
	});
	const localeLang = useLocaleLang();

	// 获取谷歌人机验证token
	const getRecaptchaToken = (): Promise<{ recaptchaTp: boolean; headers: Object | null }> => {
		if (!NeedRecaptcha) {
			return Promise.resolve({
				recaptchaTp: true,
				headers: {}
			});
		} else {
			return new Promise((resolve, reject) => {
				if (window?.grecaptcha && window.grecaptcha?.execute) {
					window.grecaptcha
						?.execute(sitekey, { action: "login" })
						.then((res: any) => {
							const recaptchaTp = res.length > 0;
							if (!recaptchaTp) {
								// 谷歌人机验证生成token为空的提示
								FsMessage({ message: localeLang("homeLang.grecaptcha.grecaptcha_error1"), type: "danger" });
							}
							resolve({ recaptchaTp, headers: { "g-recaptcha-response": res.length > 0 ? res : "" } });
						})
						.catch((err: any) => {
							console.log(err);
							// 谷歌人机验证执行错误的提示
							FsMessage({ message: localeLang("homeLang.grecaptcha.grecaptcha_error"), type: "danger" });
							resolve({ recaptchaTp: false, headers: { "g-recaptcha-response": "" } });
						});
				} else {
					// 客户端不支持谷歌环境导致谷歌人机验证不能执行的的提示
					FsMessage({ message: localeLang("homeLang.grecaptcha.grecaptcha_error1"), type: "danger" });
					resolve({ recaptchaTp: false, headers: { "g-recaptcha-response": "" } });
				}
			});
		}
	};

	onBeforeUnmount(() => {
		const callScript = document.querySelector("script[data-callType='callScript']");
		if (callScript) {
			document.head.removeChild(callScript);
		}
		if (document.querySelector(".grecaptcha-badge")) {
			document.querySelector(".grecaptcha-badge")?.remove();
		}
	});
	return {
		NeedRecaptcha,
		sitekey,
		getRecaptchaToken
	};
}
